const zh_TW = {
  'siteMetadata.title': `阿克西斯教`,
  'siteMetadata.description': `就是現在! 加入阿克西斯教吧!!!`,
  'siteMetadata.author': `@zmcx16`,

  'langSelectTips.text1': `點擊這裡切換網頁語系。`,
  'langSelectTips.text2': `也支援異世界語喔!`,
  'marquee.text1': `就是現在! 快加入阿克西斯教吧!!! 現在加入就送可以吃的肥皂再加上兩倍的點數更附上可以喝的洗衣精!!! 如果加入的同時還拉三個人一起入教的話還贈送艾莉斯教美女祭司的胖次!!!`,
  'marquee.text2': `阿克西斯教阿克西斯教阿克西斯教阿克西斯教阿克西斯教阿克西斯教阿克西斯教阿克西斯教阿克西斯教阿克西斯教阿克西斯教阿克西斯教阿克西斯教阿克西斯教阿克西斯教阿克西斯教!!!`,
  'alcanretia.text': `阿爾坎雷堤亞`,
  'axisCult.text': `阿克西斯教團`,  
  'header.text1': `歡迎你, 迷途的羔羊阿`,
  'header.text2': `傾訴你的罪孽吧`,
  'header.text3': `阿克婭女神肯定會聽到, 並賜予你救贖`,  
  'introAxis.caption1': `對人生感到絕望的冒險者A`,
  'introAxis.caption2': `精神嚴重受挫的大法師B`,
  'introAxis.caption3': `受X癖好所苦的十字騎士C`,
  'introAxis.caption4': `想要獲得救贖嗎? 那就快加入阿克西斯教吧!!`,
  'introAxis.header': `加入阿克西斯教改變你悲慘的人生吧!`,
  'introAxis.title': `只要成為阿克西斯教徒, 就能立刻享有以下加護:`,
  'introAxis.subTitle1': `不再壓抑自我, 樂於享受人生`,
  'introAxis.subTitle2': `想讓世界變美好的心靈以及行動力`,
  'introAxis.subTitle3': `討厭的人直接遠離你`,
  'introAxis.footerText1': `想要無拘無束享受未來快樂的人生嗎?`,
  'introAxis.footerText2': `快加入阿克西斯教吧!`,
  'introAqua.caption1': `高貴又美麗的阿克婭女神`,
  'introAqua.caption2': `100分的笑容, 200分的美貌以及三百億萬分的神聖氣息`,
  'introAqua.caption3': `看看這惹人憐愛的模樣`,
  'introAqua.caption4': `還需要猶豫嗎? 一起來信仰阿克婭女神吧!!`,
  'introAqua.header': `我們信仰之神: 水之女神阿克婭`,
  'introAqua.title': `關於阿克婭女神的豆知識:`,
  'introAqua.subTitle1': `多才多藝 - 花鳥風月滿級`,
  'introAqua.subTitle2': `得意料理是美乃滋鮪魚飯`,
  'introAqua.subTitle3': `一言以蔽之就是 萌萌噠 萌萌噠 萌萌噠!!!`,
  'introAqua.footerText1': `尼采曾說過:「上帝已死, 但阿克婭女神還在!」`,
  'introAqua.footerText2': `快加入阿克西斯教信奉阿克婭女神吧!!!`,
  'kaoGei.header': `想更進一步認識阿克婭女神嗎 ? 點擊下面的圖片吧!!!`,
  'cardQA.title': `加入阿克西斯教吧 問與答專欄`,
  'cardQA.front1.text': `你在日常生活中會情緒低落, 不時感到焦慮或憂鬱, 或時常壓抑自我感受嗎?`,
  'cardQA.back1.text': `<b>加入阿克西斯教完全可以解決這個問題。</b><br><br>畢竟阿克西斯教徒<b>只可能讓他人憂鬱</b>, 不可能得憂鬱症。<br><br>如果你看到阿克西斯教徒情緒低落的樣子, 唯一的可能就是他是<b>艾莉絲教信徒</b>假冒的騙子!`,
  'cardQA.front2.text': `你常遭他人單方面利用, 又不敢明確拒絕對方嗎?`,
  'cardQA.back2.text': `<b>那成為阿克西斯教徒就對了!</b><br><br>沒有任何壞人敢靠近阿克西斯教徒, 阿克西斯教徒有自己的思想, 渴望讓世界變得美好。<br><br><b>壞人唯一的命運就是改過自新, 成為新一位阿克西斯教徒。</b>`,
  'cardQA.front3.text': `你對世間的強權及壓迫感到不滿, 意欲改變現在這個不公不義的世界嗎?`,
  'cardQA.back3.text': `阿克西斯教徒每天的日常工作就是對抗既是最大國教又是貨幣單位的艾莉絲教!<br><br>丟石頭砸艾莉絲教會窗戶、散佈艾莉斯是黑暗女神、艾莉絲教徒喜歡欺負弱小的謠言等等。<br><br><b>反抗壓迫是阿克西斯教徒最出色的地方。</b>`,  
  'cardQA.front4.text': `沒有什麼特別的煩惱以及想法, 很滿意現在的生活...`,
  'cardQA.back4.text': `<b>那就更該加入阿克西斯教了!</b><br><br>阿克西斯教徒無時無刻都在享受人生, 追求讓自己過得更爽更快樂。<br><br>只要加入阿克西斯教, <b>一定會改變你的人生觀</b>, 成為世界上最快樂且最幸福的人!`,  
  'adaptiveTesting.title': `想知道阿克西斯教適不適合自己嗎? 下面的適性測驗可以測試你跟阿克西斯教的速配指數!`,
  'adaptiveTesting.reportButton': `測驗結果`,
  'adaptiveTesting.question1.text': `問題一: 你是否有過羨慕阿克西斯教徒的樂觀思考, 積極行動或是生活方式?`,
  'adaptiveTesting.question1.headerStart': `不曾有過`,
  'adaptiveTesting.question1.headerEnd': `非常羨慕`,
  'adaptiveTesting.question2.text': `問題二: 比起周遭觀感以及世人對你的評價, 你更想忠於自己的慾望而活。`,
  'adaptiveTesting.question2.headerStart': `非常不同意`,
  'adaptiveTesting.question2.headerEnd': `非常同意`,  
  'adaptiveTesting.question3.text': `問題三: 你有不能被世人而知的興趣或癖好, 並且這件事甚至到攸關性命的程度?`,
  'adaptiveTesting.question3.headerStart': `完全沒有`,
  'adaptiveTesting.question3.headerEnd': `非常同意`,  
  'adaptiveTesting.question4.text': `問題四: 看到艾莉絲教徒會莫名有想吐口水或丟東西的衝動`,
  'adaptiveTesting.question4.headerStart': `完全沒有`,
  'adaptiveTesting.question4.headerEnd': `非常同意`,  
  'adaptiveTesting.question5.text': `問題五: 試著想像一下, 如果阿克婭女神的襪子破洞了, 你會立刻幫阿克婭女神買新的襪子回來, 還會親自侍奉幫阿克婭女神穿上。`,
  'adaptiveTesting.question5.headerStart': `完全不會`,
  'adaptiveTesting.question5.headerEnd': `榮幸之至`, 
  'adaptiveTesting.report.scoreText': `測驗分數:`, 
  'adaptiveTesting.report.suitabilityText': `適性度:`, 
  'adaptiveTesting.report.lowResult': `雖然你的測驗分數不高, 不過這代表你內心是十分渴望救贖的, 尤其從測驗結果中更可以看到你對阿克婭女神的崇拜之情! 作為阿克西斯教徒最重要的一點就是對我們的女神阿克婭時時保持著敬佩與崇拜。<br><br>
  為了即將加入阿克西斯教的你, 阿克西斯教團準備了特別的課程! 如果你是可愛的小正太, 教團決定派你去阿克西斯教團阿克塞爾分部長賽西莉美女祭司身邊當見習團員。 如果你是美女就派你去教團最高負責人傑斯塔大人身旁當專屬祕書。 <b style='color:red;'>放心, 阿克西斯教徒沒有壞人。</b> 前輩們會好好對待新人並手把手從新人教育好好訓練你, 讓你早日成為傑出的阿克西斯教徒。<br><br>`,
  'adaptiveTesting.report.midResult': `從測驗結果來看, 你非常適合成為阿克西斯教徒, 阿克西斯教團非常歡迎你這樣的人才加入阿克西斯教。<br><br>
  我們團員現在剛好要去艾莉絲教會, 砸破他們家教會的窗戶玻璃。 趁著艾莉絲教徒憤怒到失去理智追趕我們時, 這時我們的榮譽教徒惠惠小姐會瀟灑的現身並解決他們。 這時你就負責混在圍觀的人群之中擺出一臉高深莫測的樣子, 一邊喃喃說著: <b style='color:red;'>「邪惡的艾莉絲教遭到天譴了...」</b><br><br>`,
  'adaptiveTesting.report.highResult': `你的測驗分數高到可以直接就任阿克西斯教團高級幹部了, 阿克西斯教團最高負責人傑斯塔大人非常欣賞你, 決定帶著你一起去拯救受苦受難的人們。 這次的任務目標是活捉蠍獅, 放心, 雖然蠍獅很強且危險度很高, 但我們傑斯塔大人身為大祭司的功力十分高強, 你不用做事只要在一旁看著傑斯塔大人對付蠍獅就好, 並且還能<b style='color:red;'>一起共享任務的報酬。</b><br><br>`,
  'adaptiveTesting.report.resultText1': `最後將測驗結果經過我們阿克西斯教超級電腦運算後, 得出你跟阿克西斯教的適性度是 <b style='color:red;'>10000%</b>`,
  'adaptiveTesting.report.resultText2': `來吧! 不要再猶豫了, 快加入阿克西斯教吧!!!`,
  'missionary.applicationFormText1': `你覺得阿克西斯教如何?`,
  'missionary.applicationFormText2': `心動了嗎? 一定心動了吧!`,
  'missionary.applicationFormText3': `現在就加入阿克西斯教吧!!!`,
  'missionary.applicationFormOption1': `立刻加入阿克西斯教`,
  'missionary.applicationFormOption2': `我已經是虔誠的阿克西斯教徒`,
  'missionary.applicationFormOption3': `我拒絕 / 下次再說`,
  'missionary.applicationFormOption4': `我是艾莉絲教徒...`,
  'missionary.joinAxisNow.text1': `歡迎加入世界上最喜歡宴會和祭典的阿克西斯教`,
  'missionary.joinAxisNow.text2': `把腦袋的螺絲轉鬆一起黑皮黑皮吧!!!`,
  'missionary.believer.text1': `體貼, 心胸開闊, 熱情, 友善, 誠實, 令人喜歡, 聰慧, 有教養, 勤奮, 善良, 慷慨, 積極, 精力充沛, 貼心, 能幹, 精力充沛, 創新, 負責任, 自由, 無私, 寬容, 適應性強, 值得信任, 有天賦, 真誠, 富同情心, 條理分明, 積極, 樂觀, 不屈不撓, 意志堅強, 上進, 聰明伶俐, 勇敢, 自信, 忠誠, 盡職, 奉獻, 虔誠, 富創造力, 浪漫, 可愛, 博學多聞, 多才多藝`,
  'missionary.believer.text2': `的阿克西斯教徒阿,`,
  'missionary.believer.text3': `願阿克婭女神保佑你有美好的一天!`,
  'missionary.doMissionary.yesButton': `加入阿克西斯教`,
  'missionary.doMissionary.noButton': `立刻逃跑`,
  'missionary.doMissionary1.textContent1': `哎呀! 剛好我很擅長占卜, 讓我幫你占卜一下吧! 就在剛才, 占卜的結果出爐了。 不久後你會遭遇不幸, 但如果加入阿克西斯教, 就能夠迴避這場災難!`,
  'missionary.doMissionary1.textContent2': `入教吧! 現在就該入教了吧!!!`,
  'missionary.doMissionary2.textContent1': `救救我! 那個長相兇惡, 疑似艾莉絲教徒的人, 想把我強押進小巷裡。 這個男人只怕強大的阿克西斯教徒! 剛好我手邊正好有阿克西斯教的入教單...`,
  'missionary.doMissionary2.textContent2': `只要有人能幫我簽上名字, 這個邪惡的艾莉絲教徒就會逃跑了!`,
  'missionary.doMissionary3.textContent1': `恭喜你! 你剛好是這個網站第一百萬名訪客! 有件特別的紀念品想贈送給你。這個紀念品是阿克西斯教獨家贊助的。`,
  'missionary.doMissionary3.textContent2': `只是個書面小程序而已, 為了領取這份紀念品, 可以請你在這張單子上面簽名嗎?`,
  'missionary.doMissionary4.textContent1': `哎呀哎呀! 好久不見~是我啦是我啦! 你過得還好嗎? 我是你的同班同學, 還有印象嗎? 自從加入阿克西斯教之後我變了不少, 你認不出來了吧!`,
  'missionary.doMissionary4.textContent2': `自從我加入阿克西斯教之後, 長得高又變漂亮了, 頭腦也靈活起來, 考試都考一百分呢! 你也想跟我一樣嗎? 一起加入阿克西斯教吧!!!`,
  'missionary.doMissionary5.textContent1': `哎呀你好! 請收下這瓶洗衣精。 不用錢啦~ 單純是阿姨喜歡你給你的! 這個洗衣精可厲害了! 畢竟受到阿克婭女神的祝福, 任何頑強髒污都洗得掉!`,
  'missionary.doMissionary5.textContent2': `偷偷告訴你, 這瓶洗衣精不只是對身體沒有任何危害, 還可以喝喔XD`,
  'missionary.doMissionary6.textContent1': `這是能夠恢復所有異常狀態的肥皂, 而且! 就算吃下肚也所無謂!!!`,
  'missionary.doMissionary6.textContent2': `純天然素材! 神聖產品! 入教即可獲得! 免費的啊!!! 立即入教還可以獲得兩倍點數! 不用錢的阿!!!`,
  'missionary.doMissionary7.textContent1': `站住!!! 為什麼不看著我的眼睛說話!!? 為什麼不加入阿克西斯教!!?`,
  'missionary.doMissionary7.textContent2': `我這是為了你好! 為了你好! 為了你好啊!!!`,
  'missionary.doMissionary8.textContent1': `哎呀你好, 我手邊剛好有一張非常特別非常特別的咖啡店招待劵! 只要你加入阿克西斯教, 就特別只送給你喔!!!`,
  'missionary.doMissionary8.textContent2': `只要你在這裡簽下名字, 就可以拿著這張招待劵到阿克賽爾位處巷弄, 生意卻特別興隆的咖啡店使用, 如何, 是不是想立刻加入阿克西斯教了!!!`,
  'introCreed.header': `阿克西斯教 教義`,
  'introCreed.text1': `阿克西斯教徒努力就能做到，你們都是能幹的人，就算失敗了也不是你的錯，不能成功都是世界的錯。`,
  'introCreed.text2': `從不開心的事情中逃避即可，逃避不是失敗，有句話就叫「逃避就是勝利」。`,
  'introCreed.text3': `猶豫過久得出的答案，無論如何選擇都會後悔，反正都要後悔，就選擇當下的快樂吧。`,
  'introCreed.text4': `別太擔憂晚年如何，未來的你是否笑著，連神明都不知道，那就樂在當下吧。`,
  'introCreed.text5': `汝，迷茫的家裡蹲啊。不要太過自責...不想努力是世間的錯，本性惡劣是環境的錯，長得醜是遺傳基因的錯。不要責備自己，將責任推卸給他人便好...。`,
  'introCreed.text6': `阿克西斯教教義第七項：汝，勿要忍耐。想喝的時候就喝，想吃的時候就吃便好。因為明天並不見得還能吃得到。`,
  'introCreed.text7': `反正都要失敗不如放手去做，失敗之後跑路就好了。`,
  'introCreed.text8': `請放心，神會赦免一切。汝，要愛巨乳，汝，要愛貧乳；阿克西斯教的教義是容許一切。即使是同性戀者，即使是喜好非人獸耳少女者，即使是蘿莉控，即使是尼特；只要對象並非不死者或惡魔少女，只要有愛且不犯法，全都可以得到赦免。`,
  'introCreed.text9': `汝，虔誠的教徒啊。為了不再受到惡魔所誘惑，記住這句咒語吧。「艾莉絲的胸部是墊出來的」。今後若是你的心又受到誘惑，就記得詠唱這句咒語。若是遇見其他受到誘惑的人，告訴他們這句咒語也是好事一樁。`,
  'introCreed.text10': `艾莉絲的胸部是墊出來的!!!<br>艾莉絲的胸部是墊出來的!!!<br>艾莉絲的胸部是墊出來的!!!`,
  'missionaryLoli.header': `都看到這裡了還等什麼?<br>快加入阿克西斯教吧!!!`,
  'footer.aquaImage.text': `阿克婭看板圖`,
  'footer.aquaImageMosaic.text': `阿克婭看板圖(蒙太奇)`,
  'footer.github': `AxisCult Github`,
  'footer.sideProjects': `zmcx16's side projects`,
  'footer.blog': `zmcx16's Blog`,
  'footer.notice.text1': `這個網站是為了介紹和推廣"為了美好世界獻上祝福"系列作品以及為了將阿克西斯教的美妙傳到世界各地所製作, 網站大多數素材皆來自"為了美好世界獻上祝福"第一、二季動畫並為美好世界製作委員會所有, 禁止將該網站素材作非法或營利使用。`,
  'footer.notice.text2': `關於網站素材的使用以及相關權利的細節部分, 請參考 `,
  'footer.readme': `README`,
}

export default zh_TW;