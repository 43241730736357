import en from "./en"

const isekai = {
  'siteMetadata.title': en['siteMetadata.title'],
  'siteMetadata.description': en['siteMetadata.description'],
  'siteMetadata.author': en['siteMetadata.author'],

  'langSelectTips.text1': en['langSelectTips.text1'],
  'langSelectTips.text2': en['langSelectTips.text2'],
  'marquee.text1': en['marquee.text1'],
  'marquee.text2': en['marquee.text2'],
  'alcanretia.text': en['alcanretia.text'],
  'axisCult.text': en['axisCult.text'],
  'header.text1': en['header.text1'],
  'header.text2': en['header.text2'],
  'header.text3': en['header.text3'],
  'introAxis.caption1': en['introAxis.caption1'],
  'introAxis.caption2': en['introAxis.caption2'],
  'introAxis.caption3': en['introAxis.caption3'],
  'introAxis.caption4': en['introAxis.caption4'],
  'introAxis.header': en['introAxis.header'],
  'introAxis.title': en['introAxis.title'],
  'introAxis.subTitle1': en['introAxis.subTitle1'],
  'introAxis.subTitle2': en['introAxis.subTitle2'],
  'introAxis.subTitle3': en['introAxis.subTitle3'],
  'introAxis.footerText1': en['introAxis.footerText1'],
  'introAxis.footerText2': en['introAxis.footerText2'],
  'introAqua.caption1': en['introAqua.caption1'],
  'introAqua.caption2': en['introAqua.caption2'],
  'introAqua.caption3': en['introAqua.caption3'],
  'introAqua.caption4': en['introAqua.caption4'],
  'introAqua.header': en['introAqua.header'],
  'introAqua.title': en['introAqua.title'],
  'introAqua.subTitle1': en['introAqua.subTitle1'],
  'introAqua.subTitle2': en['introAqua.subTitle2'],
  'introAqua.subTitle3': en['introAqua.subTitle3'],
  'introAqua.footerText1': en['introAqua.footerText1'],
  'introAqua.footerText2': en['introAqua.footerText2'],
  'kaoGei.header': en['kaoGei.header'],
  'cardQA.title': en['cardQA.title'],
  'cardQA.front1.text': en['cardQA.front1.text'],
  'cardQA.back1.text': `<b>Joining Axis Cult would solve this problem entirely.</b><br>After all, an Axis believer can only <b>make others depressed</b>, not get depressed.`,
  'cardQA.front2.text': en['cardQA.front2.text'],
  'cardQA.back2.text': `<b>Then it would be right to become an Axis believer!</b><br>No villain dares to go near an Axis believer, <b>The only fate of a bad man is to reform and become a new Axis believer.</b>`,
  'cardQA.front3.text': en['cardQA.front3.text'],
  'cardQA.back3.text': `The daily routine of the Axis believers is to fight against the Eris cult.<br>Such as <b>spreading rumors that Eris is the Goddess of darkness</b>, etc.`,
  'cardQA.front4.text': en['cardQA.front4.text'],
  'cardQA.back4.text': `<b>All the more reasons to join Axis cult!</b><br>By joining the Axis cult, <b>you will surely change your outlook on life</b>, and become the happiest person in the world!`,
  'adaptiveTesting.title': en['adaptiveTesting.title'],
  'adaptiveTesting.reportButton': en['adaptiveTesting.reportButton'],
  'adaptiveTesting.question1.text': en['adaptiveTesting.question1.text'],
  'adaptiveTesting.question1.headerStart': en['adaptiveTesting.question1.headerStart'],
  'adaptiveTesting.question1.headerEnd': en['adaptiveTesting.question1.headerEnd'],
  'adaptiveTesting.question2.text': en['adaptiveTesting.question2.text'],
  'adaptiveTesting.question2.headerStart': en['adaptiveTesting.question2.headerStart'],
  'adaptiveTesting.question2.headerEnd': en['adaptiveTesting.question2.headerEnd'],
  'adaptiveTesting.question3.text': en['adaptiveTesting.question3.text'],
  'adaptiveTesting.question3.headerStart': en['adaptiveTesting.question3.headerStart'],
  'adaptiveTesting.question3.headerEnd': en['adaptiveTesting.question3.headerEnd'],
  'adaptiveTesting.question4.text': en['adaptiveTesting.question4.text'],
  'adaptiveTesting.question4.headerStart': en['adaptiveTesting.question4.headerStart'],
  'adaptiveTesting.question4.headerEnd': en['adaptiveTesting.question4.headerEnd'],
  'adaptiveTesting.question5.text': en['adaptiveTesting.question5.text'],
  'adaptiveTesting.question5.headerStart': en['adaptiveTesting.question5.headerStart'],
  'adaptiveTesting.question5.headerEnd': en['adaptiveTesting.question5.headerEnd'],
  'adaptiveTesting.report.scoreText': en['adaptiveTesting.report.scoreText'],
  'adaptiveTesting.report.suitabilityText': en['adaptiveTesting.report.suitabilityText'],
  'adaptiveTesting.report.lowResult': en['adaptiveTesting.report.lowResult'],
  'adaptiveTesting.report.midResult': en['adaptiveTesting.report.midResult'],
  'adaptiveTesting.report.highResult': en['adaptiveTesting.report.highResult'],
  'adaptiveTesting.report.resultText1': en['adaptiveTesting.report.resultText1'],
  'adaptiveTesting.report.resultText2': en['adaptiveTesting.report.resultText2'],
  'missionary.applicationFormText1': en['missionary.applicationFormText1'],
  'missionary.applicationFormText2': en['missionary.applicationFormText2'],
  'missionary.applicationFormText3': en['missionary.applicationFormText3'],
  'missionary.applicationFormOption1': en['missionary.applicationFormOption1'],
  'missionary.applicationFormOption2': en['missionary.applicationFormOption2'],
  'missionary.applicationFormOption3': en['missionary.applicationFormOption3'],
  'missionary.applicationFormOption4': en['missionary.applicationFormOption4'],
  'missionary.joinAxisNow.text1': en['missionary.joinAxisNow.text1'],
  'missionary.joinAxisNow.text2': en['missionary.joinAxisNow.text2'],
  'missionary.believer.text1': en['missionary.believer.text1'],
  'missionary.believer.text2': en['missionary.believer.text2'],
  'missionary.believer.text3': en['missionary.believer.text3'],
  'missionary.doMissionary.yesButton': en['missionary.doMissionary.yesButton'],
  'missionary.doMissionary.noButton': en['missionary.doMissionary.noButton'],
  'missionary.doMissionary1.textContent1': en['missionary.doMissionary1.textContent1'],
  'missionary.doMissionary1.textContent2': en['missionary.doMissionary1.textContent2'],
  'missionary.doMissionary2.textContent1': en['missionary.doMissionary2.textContent1'],
  'missionary.doMissionary2.textContent2': en['missionary.doMissionary2.textContent2'],
  'missionary.doMissionary3.textContent1': en['missionary.doMissionary3.textContent1'],
  'missionary.doMissionary3.textContent2': en['missionary.doMissionary3.textContent2'],
  'missionary.doMissionary4.textContent1': en['missionary.doMissionary4.textContent1'],
  'missionary.doMissionary4.textContent2': en['missionary.doMissionary4.textContent2'],
  'missionary.doMissionary5.textContent1': en['missionary.doMissionary5.textContent1'],
  'missionary.doMissionary5.textContent2': en['missionary.doMissionary5.textContent2'],
  'missionary.doMissionary6.textContent1': en['missionary.doMissionary6.textContent1'],
  'missionary.doMissionary6.textContent2': en['missionary.doMissionary6.textContent2'],
  'missionary.doMissionary7.textContent1': en['missionary.doMissionary7.textContent1'],
  'missionary.doMissionary7.textContent2': en['missionary.doMissionary7.textContent2'],
  'missionary.doMissionary8.textContent1': en['missionary.doMissionary8.textContent1'],
  'missionary.doMissionary8.textContent2': en['missionary.doMissionary8.textContent2'],
  'introCreed.header': en['introCreed.header'],
  'introCreed.text1': en['introCreed.text1'],
  'introCreed.text2': en['introCreed.text2'],
  'introCreed.text3': en['introCreed.text3'],
  'introCreed.text4': en['introCreed.text4'],
  'introCreed.text5': en['introCreed.text5'],
  'introCreed.text6': en['introCreed.text6'],
  'introCreed.text7': en['introCreed.text7'],
  'introCreed.text8': en['introCreed.text8'],
  'introCreed.text9': en['introCreed.text9'],
  'introCreed.text10': en['introCreed.text10'],
  'missionaryLoli.header': en['missionaryLoli.header'],
  'footer.aquaImage.text': en['footer.aquaImage.text'],
  'footer.aquaImageMosaic.text': en['footer.aquaImageMosaic.text'],
  'footer.github': en['footer.github'],
  'footer.sideProjects': en['footer.sideProjects'],
  'footer.blog': en['footer.blog'],
  'footer.notice.text1': en['footer.notice.text1'],
  'footer.notice.text2': en['footer.notice.text2'],
  'footer.readme': en['footer.readme'],
}

export default isekai;