const en = { 
  'siteMetadata.title': `Axis Cult`,
  'siteMetadata.description': `JOIN AXIS CULT NOW!!!`,
  'siteMetadata.author': `@zmcx16`,

  'langSelectTips.text1': `Click here to switch the language to view the website.`,
  'langSelectTips.text2': `We also support the language of the fantasy world!`,
  'marquee.text1': `JOIN AXIS CULT NOW!!! You can get the edible soap and drinkable detergent and double points if you join the AXIS FAITH! You also can get the pants of beauty Eris believer if you invite three people to join the AXIS FAITH!!!`,
  'marquee.text2': `AXIS CULT AXIS CULT AXIS CULT AXIS CULT AXIS CULT AXIS CULT AXIS CULT AXIS CULT AXIS CULT AXIS CULT AXIS CULT AXIS CULT AXIS CULT AXIS CULT AXIS CULT AXIS CULT AXIS CULT AXIS CULT AXIS CULT AXIS CULT AXIS CULT AXIS CULT AXIS CULT`,
  'alcanretia.text': `Alcanretia`,
  'axisCult.text': `Axis Cult`,
  'header.text1': `Welcome, lost lamb`,
  'header.text2': `Now, tell your sins`,
  'header.text3': `Goddess Aqua will hear them, and will surely grant you salvation`,
  'introAxis.caption1': `Adventurer A who is desperate for life`,
  'introAxis.caption2': `The severely mentally disturbed Archmage B`,
  'introAxis.caption3': `The cross knight C who suffers from X-addiction`,
  'introAxis.caption4': `Want to get salvation? Join Axis Cult now!`,
  'introAxis.header': `Join Axis Cult to change your miserable life!`,
  'introAxis.title': `Become an Axis Believer, you will receive the following blessing:`,
  'introAxis.subTitle1': `Get a brand new life`,
  'introAxis.subTitle2': `Want to make the world a better place`,
  'introAxis.subTitle3': `The nasty people stay away from you`,
  'introAxis.footerText1': `Want to enjoy the future of happy life?`,
  'introAxis.footerText2': `JOIN AXIS CULT NOW!`,
  'introAqua.caption1': `The noble and beautiful Goddess Aqua`,
  'introAqua.caption2': `100 points smile, 200 points Beauty and 30 billion points sanctity`,
  'introAqua.caption3': `Look at this adorable face`,
  'introAqua.caption4': `Hesitate anymore? Let's faith in Goddess Aqua!`,
  'introAqua.header': `Our Faith: Goddess of water Aqua`,
  'introAqua.title': `Trivia about Goddess Aqua:`,
  'introAqua.subTitle1': `Versatile - Nature's Beauty full level`,
  'introAqua.subTitle2': `Specialty dish is Tuna-Mayo rice`,
  'introAqua.subTitle3': `To put it in a nutshell, MOE MOE MOE!!!`,
  'introAqua.footerText1': `Nietzsche said: "God is dead, but Goddess Aqua remains!"`,
  'introAqua.footerText2': `JOIN AXIS CULT AND FAITH IN GODDESS AQUA NOW!`,
  'kaoGei.header': `Want to know more about the Goddess Aqua? Click on the images below!!!`,
  'cardQA.title': `Join Axis Cult Q & A`,
  'cardQA.front1.text': `Do you get depressed in your daily life, feel anxious or depressed from time to time, or often repress your feelings?`,
  'cardQA.back1.text': `<b>Joining Axis Cult would solve this problem entirely.</b><br>After all, an Axis believer can only <b>make others depressed</b>, not get depressed.<br>If you see the depressed look on the face of an Axis believer, he is a fake liar by an <b>Eris believer</b>!`,
  'cardQA.front2.text': `Do you often get taken advantage of by others, and you're afraid to reject them explicitly?`,
  'cardQA.back2.text': `<b>Then it would be right to become an Axis believer!</b><br>No villain dares to go near an Axis believer, an Axis believer with a mind of his own and a desire to make the world a better place.<br><b>The only fate of a bad man is to reform and become a new Axis believer.</b>`,
  'cardQA.front3.text': `Are you dissatisfied with the world's power and oppression, and want to change the current unjust world?`,
  'cardQA.back3.text': `The daily routine of the Axis believers is to fight against the Eris cult.<br>Throwing stones into Eris church windows, spreading rumors that Eris is the Goddess of darkness, etc.<br><b>Resistance to oppression is what the Axis believers do best.</b>`,
  'cardQA.front4.text': `No particular worries or thoughts, happy with the current life now...`,
  'cardQA.back4.text': `<b>All the more reasons to join Axis cult!</b><br>An Axis believer always enjoys life all the time and seeks to make his or her life more enjoyable.<br>By joining the Axis cult, <b>you will surely change your outlook on life</b>, and become the happiest person in the world!`,
  'adaptiveTesting.title': `Do you want to know if the Axis Cult is right for you? The following adaptive testing can test the suitability!`,
  'adaptiveTesting.reportButton': `Test Result`,
  'adaptiveTesting.question1.text': `Q1. Have you ever admired the optimism, activism or lifestyle of the Axis Believers?`,
  'adaptiveTesting.question1.headerStart': `Never`,
  'adaptiveTesting.question1.headerEnd': `Most admired`,
  'adaptiveTesting.question2.text': `Q2. You want to live your life for you more than the way you are perceived and evaluated by the world.`,
  'adaptiveTesting.question2.headerStart': `Not at all`,
  'adaptiveTesting.question2.headerEnd': `Strongly agree`,
  'adaptiveTesting.question3.text': `Q3. Do you have interests or hobbies that aren't known to the world, and it's even life-threatening?`,
  'adaptiveTesting.question3.headerStart': `Not at all`,
  'adaptiveTesting.question3.headerEnd': `Strongly agree`,
  'adaptiveTesting.question4.text': `Q4. You want to spit or throw something inexplicably when an Eris cultist in front of you.`,
  'adaptiveTesting.question4.headerStart': `Not at all`,
  'adaptiveTesting.question4.headerEnd': `Strongly agree`,
  'adaptiveTesting.question5.text': `Q5. Try to imagine it. If Goddess Aqua's sock has a hole, You will buy a new pair for Goddess Aqua right now, and serve her and help her wear the socks.`,
  'adaptiveTesting.question5.headerStart': `Not at all`,
  'adaptiveTesting.question5.headerEnd': `It's my honor`,
  'adaptiveTesting.report.scoreText': `Score:`,
  'adaptiveTesting.report.suitabilityText': `Suitability:`,
  'adaptiveTesting.report.lowResult': `Although your test score is not high, it means that you have a great desire for redemption in your heart, especially as you can see from the test results how much you worship the Goddess Aqua! One of the most important things about being an Axis believer is the constant admiration and worship of our Goddess Aqua.<br><br>
  For you who are about to join the Axis Church, the Axis Church has prepared a special course for you! If you are a cute little boy, the Church has decided to send you to be a trainee with Cecily, the beautiful priest of the Axis Church's Axel branch. If you're a pretty girl, send you to be the secretary of the Church's highest-ranking official Lord Zesta. <b style='color:red;'>Don't worry, there are no villains among the Axis believers.</b> The seniors will treat the newbie well and train you by holding hands from their education so that you will become an outstanding Axis believer soon.<br><br>`,
  'adaptiveTesting.report.midResult': `Judging from the test results, you are very suitable to become an Axis believer, and the Axis Church would welcome someone of your caliber to join the Axis Church.<br><br>
  Our believers will smash the windows of Eris Church. Megumin will declare herself coolly and get rid of the angry Eris Cultists who would be chasing Axis believers. You will sneak into the crowd and mutter:  <b style='color:red;'>'Divine retribution had befallen on those evil Eris Cultist...'</b><br><br>`,
  'adaptiveTesting.report.highResult': `Your test scores are high enough to make you a direct senior officer of the Axis Church, and the Lord Zesta, the highest-ranking officer of the Axis Church, admires you so much that he decided to take you with him to save the suffering people. This mission is to capture the manticore alive, don't worry, although the manticore is very strong and dangerous, the Lord Zesta is a powerful arch priest, you don't have to do anything, just watch the Lord Zesta against the manticore, and <b style='color:red;'>share the rewards of the mission together.</b><br><br>`,
  'adaptiveTesting.report.resultText1': `Finally, the results of our Axis Supercomputer show that your suitability for the Axis Church is <b style='color:red;'>10000%</b>`,
  'adaptiveTesting.report.resultText2': `Don't hesitate anymore! JOIN AXIS CULT NOW!!!`,
  'missionary.applicationFormText1': `How do you think of Axis Cult?`,
  'missionary.applicationFormText2': `DokiDoki? Absolutely DokiDoki right?`,
  'missionary.applicationFormText3': `JOIN AXIS CULT NOW!!!`,
  'missionary.applicationFormOption1': `Join Axis Cult now`,
  'missionary.applicationFormOption2': `I am a devout Axis Believer`,
  'missionary.applicationFormOption3': `No / Next time`,
  'missionary.applicationFormOption4': `I am an Eris Believer...`,
  'missionary.joinAxisNow.text1': `Welcome to join the world's favorite banquets and celebrations Axis Cult!`,
  'missionary.joinAxisNow.text2': `It's Party Time!!!`,
  'missionary.believer.text1': `Considerate, open-minded, enthusiastic, friendly, honest, agreeable, intelligent, cultured, diligent, kind, generous, active, lively, sweet, capable, energetic, innovative, responsible, libral, selfless, tolerant, adaptable, trustworthy, gifted, sincere, sympathetic, logical, motivated, optimistic, perservering, purposeful, pushy, clever, brave, confident, faithful, dutiful, devoted, religious, creative, romantic, likeable, erudite, versatile `,
  'missionary.believer.text2': `Axis believer,`,
  'missionary.believer.text3': `Goddess Aqua will bless you to have a wonderful day!`,
  'missionary.doMissionary.yesButton': `Join Axis Cult`,
  'missionary.doMissionary.noButton': `Run away`,
  'missionary.doMissionary1.textContent1': `I'm actually great at telling fortunes! Why don't you let me read your future? I've seen your future! Grave misfortune awaits for you! But if you convert to the Axis faith, your misfortune can be avoided!`,
  'missionary.doMissionary1.textContent2': `Join us! Why don't you join us?`,
  'missionary.doMissionary2.textContent1': `Help me! This violent-looking man, who's probably an Eris cultist, is trying to force me into the dark ways! He is only afraid of Axis church! and all I have on me is the paperwork for joining the Axis Church...`,
  'missionary.doMissionary2.textContent2': `If someone would sign their name, this sinister Eris cultist would flee in fear!`,
  'missionary.doMissionary3.textContent1': `Congratulation! You are the millionth to visit the website. I'd love to give you a commemorative prize, the prize is sponsored by the Axis Church,`,
  'missionary.doMissionary3.textContent2': `so if you'd just help me get this paperwork out of the way, we can get your name, and you can receive your prize...`,
  'missionary.doMissionary4.textContent1': `Hey Hey! It's been so long! It's me! You know, me! How've you been? Remember? We were classmates in school! I've changed a lot since joining the Axis Church.`,
  'missionary.doMissionary4.textContent2': `Since I joining Axis Church, I got taller and prettier, and I find myself much cleverer, I've got full marks in each examination. Do you want to change your life? Join Axis Church now!!!`,
  'missionary.doMissionary5.textContent1': `Hello! Take this detergent. Oh, Don't worry about paying! It's a little gift from old lady! It's actually some amazing stuff! It's received Goddess Aqua's blessing, so it can get rid of any kind of stain.`,
  'missionary.doMissionary5.textContent2': `It's so easy on your skin! I'm serious! You can even drink it!`,
  'missionary.doMissionary6.textContent1': `This soap can cure any kind of abnormal status, and not only that... It's actually edible!!!`,
  'missionary.doMissionary6.textContent2': `Natural ingredients! Sacred! You can get some if you join the Axis faith! Double points! All free!!!`,
  'missionary.doMissionary7.textContent1': `Hey, why won't you look me in the eye when I'm talking to you?! Why don't you join us?!`,
  'missionary.doMissionary7.textContent2': `I'm doing this for your sake!!! It's for your own good!!!`,
  'missionary.doMissionary8.textContent1': `Hey, I have a very special coffee shop reception ticket. You can get it if you join Axis Church!`,
  'missionary.doMissionary8.textContent2': `You can take this ticket to the coffee shop which location on Axel city after you sign your name on this paperwork. Do you want to join Axis Church now?`,
  'introCreed.header': `Axis Cult Credo`,
  'introCreed.text1': `The Axis Church can get things done. And because you can get things done, even if it doesn't go well, it’s not your fault! It's society's fault that things don't work out!`,
  'introCreed.text2': `You can run away from unpleasant things! That doesn't mean you've lost! Because, as they say, "Sometimes running away is winning"!`,
  'introCreed.text3': `The answer you come to after being in doubt is usually something you'll regret, no matter what you choose! If you're going to regret it anyway, do whatever’s easiest for you in the moment!`,
  'introCreed.text4': `Do not fear growing old. Not even God knows whether you will be happy in the future, so you should, at least, be happy now!`,
  'introCreed.text5': `Thou hikikomori who is at a lost. Don't blame yourself too much... It is the world's fault that thou don't want to work hard, it is thy environment's fault that your personality is twisted, it is thy gene's fault for being ugly. Don't blame thyself, just push the blame to others...`,
  'introCreed.text6': `The seventh doctrine of Axis Cult:<br>You shall not endure. Drink when you want to drink. Eat when you want to eat. Because you might not get to eat it tomorrow...`,
  'introCreed.text7': `Since it's pointless anyway, go ahead and try it. If you failed, then just run away.`,
  'introCreed.text8': `Rest easy, God forgives all. Thou shalt love big breasts. Thou shalt love small breasts. The Axis faith teaches forgiveness in all things. Whether you’re a lolicon, a NEET, or a questionable-human fan of animal-eared girls. As long as you’re not interested in undead or devil girls, you have love in your heart and you don’t break the law, all will be forgiven.`,
  'introCreed.text9': `Faithful follower, I will share holy words that will help you avoid demonic temptation. Eris pads her chest. The next time you feel you are being led astray, chant these words. If you know anyone else being tempted, you can share it with them.`,
  'introCreed.text10': `Eris pads her chest!!!<br>Eris pads her chest!!!<br>Eris pads her chest!!!`,
  'missionaryLoli.header': `What are you waiting for?<br>JOIN AXIS CULT NOW!!!`,
  'footer.aquaImage.text': `Aqua Image`,
  'footer.aquaImageMosaic.text': `Aqua Image(mosaic)`,
  'footer.github': `AxisCult Github`,
  'footer.sideProjects': `zmcx16's side projects`,
  'footer.blog': `zmcx16's Blog`,
  'footer.notice.text1': `This website is made to introduce and promote KonoSuba series work, and do Axis Cult missionary work around the world, The web resources which the Konousba Anime images copyright belongs to Konosuba Committee, do not use for commercial/profit purpose.`,
  'footer.notice.text2': `The details about the website materials and related rights, Ref: `,
  'footer.readme': `README`,  
}

export default en;